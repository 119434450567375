import { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { selectAllSubCategories } from '../../selectors';
import { THEMES } from '../../constants';
import HeaderCatalog from '../HeaderCatalog';
import { HeaderContent } from './components';
import { useActiveCategory } from '../HeaderCatalog/hooks';

import styles from './style.module.scss';

const Header = memo(({ theme = null, style, fixed }) => {
  const { category } = useParams();
  const [showMenu, setShowMenu] = useState(false);
  const { selectCategories } = useActiveCategory();
  const subCategories = useSelector(selectAllSubCategories(category));
  const hasBlackTheme = theme === THEMES.black;
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (category && subCategories.length) {
      selectCategories(category)();
    }
  }, [category, selectCategories, subCategories.length]);

  const handlerHideMenu = () => {
    setShowMenu(false);
  };

  return (
    <div className={classnames(styles.Header, {
      [styles.Header_black]: hasBlackTheme,
      [styles.Header_black_open]: showSearch && hasBlackTheme,
      [styles.Header_fixed]: fixed
    })} style={style}>
      <HeaderCatalog
        isShow={showMenu}
        setHide={handlerHideMenu}
      />
      <HeaderContent
        theme={theme}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
      />
    </div>
  );
});

Header.displayName = 'Header';

export default Header;
