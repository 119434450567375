import {validateEmail} from "../../../helpers";

export const validateOrdering = (delivery) => (values) => {
  const errors = {};
  const required = 'Поле обязательно!';
  const oneOfNamesHasBeenLost = 'Укажите имя и фамилию';
  const secondNameHasBeenLost = 'Укажите фамилию';
  const namesWithNumbers = 'Поле не должно содержать цифры';

  const checkNameErrors = str => {
    if (!str) {
      return required;
    }

    if (/\d/.test(str)) {
      return namesWithNumbers;
    }

    if (values.name.split(' ').length < 2) {
      return oneOfNamesHasBeenLost;
    }

    if (values.name.split(' ')[1] === '') {
      return secondNameHasBeenLost;
    }

    return false;
  };

  if (!(values.phone && values.phone.length === 18)) {
    errors.phone = required;
  }

  const nameError = checkNameErrors(values.name);

  if (nameError) {
    errors.name = nameError;
  }

  if (!values.email) {
    errors.email = required;
  }

  else if (!validateEmail(values.email)) {
    errors.email = 'Неверный формат email.';
  }

  if (!values.agree) {
    errors.agree = required;
  }

  // if (!values.city && values.courier_place === 'toDoor' && delivery.type !== 'pickup') {
  //   errors.city = required;
  // }
  //
  // if (!values.address && values.courier_place === 'toDoor' && delivery.type !== 'pickup') {
  //   errors.address = required;
  // }

  return errors;
};
